import { useMemo, useState } from 'react';
import { dehydrate, DehydratedState, Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import { GetServerSideProps } from 'next';

import { web_path } from '@common/constants';
import { isTossApp, useIsToss } from '@common/device-manager';
import { convertToTossWebPDPLink, convertToWebPDPLink } from '@common/pdp';
import { GlobalNavigationContainer, GNBConfigProvider } from '@widgets/global-navigation-bar';
import { LikeSubject, useLikeObserve } from '@widgets/like-product';
import { ProductCardProvider, VerticalProductCardContextType } from '@widgets/product-card';
import { convertToSupertossUrl } from '@widgets/web-landing-banner';
import { PAGE_ID_WEB_HOME } from '@domains/home-common';
import { bannerQueryOptions } from '@domains/home-features';
import { MainScreen } from '@domains/home-screens';

import { withKakaoAutoLogin } from 'util/auth/kakao-auto-login';

const WEB_PATH = {
  auth: { root: '/auth' },
  home: '/home',
  orders: '/orders',
  root: '/',
  search: '/search',
  cart: '/cart',
  categories: {
    root: '/categories',
  },
  product: {
    detail: '/catalog/products',
  },
  my_page: {
    root: '/my-page',
  },
  picks: {
    root: '/picks',
  },
  my_review: {
    list: web_path.my_review_list,
    detail: web_path.review_detail,
    completed: web_path.review_completed,
  },
};

export interface HomeServerSideProps {
  dehydratedState: DehydratedState;
}

export function Page({ dehydratedState }: HomeServerSideProps) {
  const isToss = useIsToss();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: { queries: { cacheTime: 300000, refetchOnWindowFocus: false, staleTime: 300000, retry: 1 } },
      }),
  );

  const productCardContextValue = useMemo<VerticalProductCardContextType>(() => {
    return {
      href: (item) =>
        isToss
          ? convertToSupertossUrl(location.origin + convertToTossWebPDPLink({ product_url: item.productUrl }))
          : convertToWebPDPLink({ product_url: item.productUrl }),
    };
  }, [isToss]);

  return (
    <QueryClientProvider client={queryClient}>
      <LikeSubject>
        <ProductCardProvider
          useLikeObserver={useLikeObserve}
          verticalProductCard={productCardContextValue}
          horizontalProductCard={productCardContextValue}
        >
          <Hydrate state={dehydratedState}>
            <MainScreen path={WEB_PATH} />
            <GNBConfigProvider path={WEB_PATH} page_id={PAGE_ID_WEB_HOME}>
              <GlobalNavigationContainer />
            </GNBConfigProvider>
          </Hydrate>
        </ProductCardProvider>
      </LikeSubject>
    </QueryClientProvider>
  );
}

Page.getLayout = (page) => page;

export const getServerSideProps: GetServerSideProps<HomeServerSideProps> = withKakaoAutoLogin(
  async ({ req, query }) => {
    const query_client = new QueryClient();

    const userAgent = req.headers['user-agent'] || '';
    const isToss = isTossApp(userAgent);
    const external_page_id = isToss ? 'toss_shopping_home' : '';

    // 네이버 웹 문서 수집 검색 로봇 제외
    if (/(Yeti)/i.test(userAgent) || /(Naverbot)/i.test(userAgent)) {
      return {
        props: {
          dehydratedState: dehydrate(query_client),
        },
      };
    }

    await Promise.allSettled([query_client.prefetchQuery(bannerQueryOptions(req, { external_page_id }))]);

    return {
      props: { dehydratedState: JSON.parse(JSON.stringify(dehydrate(query_client))) },
    };
  },
);

export default Page;
